var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.vm.loading)?_c('div',[_c('v-container',[(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticStyle:{"position":"sticky","top":"118px"}},[_c('SummaryInformation',{on:{"onTabClick":_vm.toggleComponent}})],1)]),_c('v-col',{attrs:{"cols":"8"}},[_c('ProjectDetailHeader'),_c(_vm.componentName,{tag:"component"})],1)],1):_c('div',[_c('div',[_c('ProjectDetailHeader',{staticClass:"mb-7"}),_c('SummaryInformation')],1),_c('div',[_c('div',{staticClass:"mt-10"},[_c('TabCustom')],1)])])],1),(_vm.$vuetify.breakpoint.smOnly && _vm.vm.canGoWhitelist && !_vm.vm.fetching)?_c('div',{staticClass:"position-fixed z-1000 algo-grey darken-2 pa-5 fill-width",staticStyle:{"bottom":"0"}},[_c('v-btn',{staticClass:"project-button font-weight-bold black--text",class:{
        primary: _vm.vm.statusColor === 'primary',
        'algo-grey btnC--disabled': _vm.vm.statusColor === 'grey-lighten',
        error: _vm.vm.statusColor === 'error',
        'algo-grey footer-project-card lighten-4': _vm.vm.statusColor === 'violet-lighten',
        'algo-grey footer-project-card lighten-3': _vm.vm.statusColor === 'blue-lighten',
        'algo-grey footer-project-card lighten-2': _vm.vm.statusColor === 'blue-lighten-2',
      },attrs:{"block":"","height":"50"},on:{"click":_vm.applyPool}},[_vm._v(" "+_vm._s(_vm.vm.poolStatus))])],1):(_vm.$vuetify.breakpoint.smOnly && _vm.vm.fetching)?_c('div',{staticClass:"d-flex justify-center position-fixed z-1000 algo-grey darken-2 pa-5 fill-width",staticStyle:{"bottom":"0"}},[_c('v-progress-circular',{attrs:{"size":35,"color":"primary","indeterminate":""}})],1):_vm._e()],1):_c('div',[_c('v-skeleton-loader',{staticClass:"ma-16",attrs:{"type":"card, table-tfoot, card-heading, image"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }