import { observable, computed, action, reaction, IReactionDisposer, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { PoolsStore, poolsStore } from '@/stores/pools-store'
import { PoolStore } from '@/stores/pool-store'
import { apiService } from '@/services/api-services'
import { PoolModel } from '@/models/pool-model'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { walletStore } from '@/stores/wallet-store'
import { promiseHelper } from '@/helper/promise-helper'

export class ProjectDetailViewModel {
  @observable loading = false
  @observable poolStore?: PoolStore = undefined
  _disposers: IReactionDisposer[] = []
  @observable apply: any = null
  @observable fetchingApply = false
  constructor() {
    this._disposers = [
      reaction(
        () => walletStore.jwt,
        (x) => {
          this.apply = null
          if (x) {
            this.fetchApply()
          }
        },
        { fireImmediately: true }
      ),
    ]
  }
  @asyncAction *fetchApply() {
    yield when(() => !!this.pool)
    try {
      if (this.poolStore?.poolState?.transformStatusText === 'UPCOMING') return
      this.fetchingApply = true
      const res: any = yield apiService.applies.find({
        pool: this.pool?.id,
        investor: walletStore?.investor?.id,
        walletAddress: walletStore.account,
      }) || []
      if (res.length > 0) this.apply = res[0]
    } catch (e) {
      console.log('fetchApply is fail')
    } finally {
      setTimeout(() => {
        this.fetchingApply = false
      }, 3000)
    }
  }

  @computed get canGoWhitelist() {
    if (!this.isWhitelistEnded) return true
    if (!this.apply) return false
    return this.apply?.status === 'whitelisted'
  }

  @asyncAction *loadPool(unicodeName: string) {
    try {
      this.loading = true
      this.poolStore = yield poolsStore.getPool(unicodeName)
    } catch (error: any) {
      snackController.error(error.message)
    } finally {
      this.loading = false
    }
  }

  @computed get fetching() {
    return this.fetchingApply || this.loading
  }

  @computed get poolStatus() {
    return this.poolStore?.poolState?.status
  }
  @computed get statusColor() {
    return this.poolStore?.poolState?.statusColor
  }
  @computed get poolTargetTime() {
    return this.poolStore?.poolState?.targetTime
  }
  @computed get pool() {
    return this.poolStore?.pool
  }
  @computed get isWhitelistEnded() {
    return this.poolStore?.poolState?.isWhitelistEnded
  }
}
