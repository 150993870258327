

































































import { Component, Vue, Watch, Provide } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { ProjectDetailViewModel } from '../viewmodels/project-detail-viewmodel'

@Observer
@Component({
  components: {
    SummaryInformation: () => import('../components/summary-infomation.vue'),
    TokenSale: () => import('../components/tab-item/token-sale-tab.vue'),
    ContractMetric: () => import('../components/tab-item/contract-metrics-tab.vue'),
    VestingSchedule: () => import('../components/tab-item/vesting schedule.vue'),
    Description: () => import('../components/tab-item/description-tab.vue'),
    ProjectDetailHeader: () => import('../components/project-detail-header.vue'),
    AboutContent: () => import('../components/about-content.vue'),
    TabCustom: () => import('../components/tab-custom.vue'),
  },
})
export default class ProjectDetail extends Vue {
  @Provide() vm = new ProjectDetailViewModel()

  @Watch('$route.params.unicodeName', { immediate: true }) async onPoolIdChanged(val: string) {
    if (val) {
      await this.vm.loadPool(val)
    }
  }

  componentName = 'description'
  toggleComponent(value: string): void {
    this.componentName = value
    this.$forceUpdate()
  }
  applyPool() {
    this.$router.push(`${this.vm.poolStore?.pool?.unicodeName}/whitelist`)
  }
}
